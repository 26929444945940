/* ADD MEDIA QUERY FOR MOBILE LATER */

* {
  box-sizing: border-box;
}

:root {
  --white: #fff;
  --black: rgb(14, 13, 13);
  --active-color: #f1f1f1;
  --border-radius: 20px;
}

.tab-links {
  padding: 0;
  margin: 0 auto 20px;
  list-style: none;
  max-width: 100%;
  display: flex;
}

.tab {
  position: relative;
}

.tab a {
  text-decoration: none;
  color: var(--black);
}

.tab::before {
  content: "";
  left: 25px;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  position: absolute;
  border-radius: 9px;
  transition: background 0.5s ease;
  border-bottom: 1px solid black;
}

.tab.icon-tab::before {
  left: 0 !important;
}

/* .tab svg {
    min-width: 30px;
    fill: var(--black);
    transition: fill 0.5s ease;
    font-size: 500%;
} */

.tab.active::before {
  background: var(--active-color);
}

.tab span {
  font-weight: 700;
  margin-left: 20px;
  transition: color 0.5s ease;
}

.tab.active span {
  color: var(--active-color);
}

.tab.active svg {
  fill: var(--active-color);
}

.tab a {
  display: flex;
  align-items: center;
  font-weight: 500;
  overflow: hidden;
  position: relative;
}

.tab-content {
  display: none;
}

.tab-content.active {
  display: block;
}

.description {
  font-size: 90%;
}

/* UPDATE TABS */
.update-tab-component {
  max-width: 100%;
  margin: auto;
  background: var(--white);
  padding: 10px;
  border-radius: 9px;
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.1);
}

.update-tab-links {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
}

.update-tab {
  position: relative;
}

.update-tab a {
  text-decoration: none;
  color: var(--black);
}

.update-tab::before {
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0.2;
  position: absolute;
  border-radius: 9px;
  background: none;
  transition: background 0.5s ease;
  border-bottom: 1px solid black;
}

.update-tab svg {
  min-width: 30px;
  fill: var(--black);
  transition: fill 0.5s ease;
  font-size: 500%;
}

.update-tab.active::before {
  background: var(--active-color);
}

.update-tab span {
  font-weight: 700;
  margin-left: 20px;
  transition: color 0.5s ease;
}

.update-tab.active span {
  color: var(--active-color);
}

.update-tab.active svg {
  fill: var(--active-color);
}

.update-tab a {
  padding: 2px;
  display: flex;
  align-items: center;
  font-weight: 500;
  overflow: hidden;
  position: relative;
}

.update-tab-content {
  display: none;
}

.update-tab-content.active {
  display: block;
}

.description {
  font-size: 90%;
}
