@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900");

.order-container {
  display: grid;
  grid-template-columns: 700px auto;
  grid-template-rows: 0.7fr auto;
  grid-template-areas:
    "order-content0 order-content0"
    "order-content2 order-content3"
    "order-content4 order-content5"
    "order-content6 order-content7"
    "order-content8 order-content9"
    "order-content10 order-content11"
    "order-content12 order-content13"
    "order-content14 order-content15"
    "order-content16 order-content17"
    "order-content18 order-content19"
    "order-content20 order-content21"
    "order-content22 order-content23"
    "order-content24 order-content24"
    "order-content25 order-content25"
    "order-content-update order-content-update"
    "order-content1 order-content1"
    "order-content26 order-content26"
    "order-content27 order-content27"
    "order-content28 order-content28";
  /* "order-content23 order-content24"
    "order-content25 order-content25"
    "order-content26 order-content26"
    "order-content27 order-content27"
    "order-content28 order-content28"; */
  grid-gap: 0.5rem;
  border-radius: 25px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.788);
}

.order-list-text {
  font-size: 1rem;
  font-weight: 500;
  color: rgb(61, 21, 21);
  font-family: Montserrat;
}

.shipment-text {
  font-size: 12pt;
  color: rgb(61, 21, 21);
  font-family: Montserrat;
}

.shipping-status-text {
  font-size: 10pt;
  color: rgb(194, 146, 14);
  font-family: Montserrat;
}

#order-content-update {
  grid-area: order-content-update;
}

#order-content0 {
  grid-area: order-content0;
}

#order-content1 {
  grid-area: order-content1;
  text-align: center;
}

#order-content2 {
  grid-area: order-content2;
  background-color: rgba(152, 158, 158, 0.274);
}

#order-content3 {
  grid-area: order-content3;
  background-color: rgba(152, 158, 158, 0.288);
}

#order-content3 {
  grid-area: order-content3;
}

#order-content4 {
  grid-area: order-content4;
}

#order-content5 {
  grid-area: order-content5;
}

#order-content6 {
  grid-area: order-content6;
}

#order-content7 {
  grid-area: order-content7;
}

#order-content8 {
  grid-area: order-content8;
  border-bottom: 1px solid black;
}

#order-content9 {
  grid-area: order-content9;
  border-bottom: 1px solid black;
}

#order-content10 {
  grid-area: order-content10;
}

#order-content11 {
  grid-area: order-content11;
}

#order-content12 {
  grid-area: order-content12;
  border-bottom: 1px solid black;
}

#order-content13 {
  grid-area: order-content13;
  border-bottom: 1px solid black;
}

#order-content14 {
  grid-area: order-content14;
}

#order-content15 {
  grid-area: order-content15;
}

#order-content16 {
  grid-area: order-content16;
  border-bottom: 1px solid black;
}

#order-content17 {
  grid-area: order-content17;
  border-bottom: 1px solid black;
}

#order-content18 {
  grid-area: order-content18;
}

#order-content19 {
  grid-area: order-content19;
}

#order-content20 {
  grid-area: order-content20;
  border-bottom: 1px solid black;
}

#order-content21 {
  grid-area: order-content21;
  border-bottom: 1px solid black;
}

#order-content22 {
  grid-area: order-content22;
}

#order-content23 {
  grid-area: order-content23;
  font-weight: 600;
}

#order-content24 {
  grid-area: order-content24;
  font-weight: 600;
  font-size: 15pt;
  display: flex;
  flex-direction: row;
  grid-auto-flow: column;
}

#order-content25 {
  grid-area: order-content25;
  font-weight: 600;
  font-size: 15pt;
  display: flex;
  flex-direction: row;
  grid-auto-flow: column;
}

#order-content26 {
  grid-area: order-content26;
}

#order-content27 {
  grid-area: order-content27;
}

#order-content28 {
  grid-area: order-content28;
}

@media only screen and (max-width: 600px) {
  .order-container {
    grid-template-columns: 250px auto;
    grid-template-rows: 0.2fr auto;
    grid-template-areas:
      "order-content0 order-content0"
      "order-content2 order-content3"
      "order-content4 order-content5"
      "order-content6 order-content7"
      "order-content8 order-content9"
      "order-content10 order-content11"
      "order-content12 order-content13"
      "order-content14 order-content15"
      "order-content16 order-content17"
      "order-content18 order-content19"
      "order-content20 order-content21"
      "order-content22 order-content23"
      "order-content24 order-content24"
      "order-content25 order-content25"
      "order-content-update order-content-update"
      "order-content1 order-content1"
      "order-content26 order-content26"
      "order-content27 order-content27"
      "order-content28 order-content28";
  }
}

/*Quote Summary Container Order Details*/
.quote-order-container {
  display: grid;
  grid-template-columns: 500px auto;
  grid-template-rows: 0.7fr auto;
  grid-template-areas:
    "quote-order-content0 quote-order-content0"
    "quote-order-content1 quote-order-content2"
    "quote-order-content3 quote-order-content4"
    "quote-order-content5 quote-order-content6"
    "quote-order-content7 quote-order-content8"
    "quote-order-content10 quote-order-content11"
    "quote-order-content12 quote-order-content13"
    "quote-order-content14 quote-order-content15";
  /* "quote-order-content23 quote-order-content24"
    "quote-order-content25 quote-order-content25"
    "quote-order-content26 quote-order-content26"
    "quote-order-content27 quote-order-content27"
    "quote-order-content28 quote-order-content28"; */
  grid-gap: 0.5rem;
  border-radius: 25px;
}

#quote-order-content0 {
  grid-area: quote-order-content0;
  text-align: center;
}

#quote-order-content1 {
  grid-area: quote-order-content1;
}
#quote-order-content2 {
  grid-area: quote-order-content2;
}

#quote-order-content3 {
  grid-area: quote-order-content3;
}

#quote-order-content3 {
  grid-area: quote-order-content3;
}

#quote-order-content4 {
  grid-area: quote-order-content4;
}

#quote-order-content5 {
  grid-area: quote-order-content5;
}

#quote-order-content6 {
  grid-area: quote-order-content6;
}

#quote-order-content7 {
  grid-area: quote-order-content7;
}

#quote-order-content8 {
  grid-area: quote-order-content8;
  quote-order-bottom: 1px solid black;
}

#quote-order-content9 {
  grid-area: quote-order-content9;
  quote-order-bottom: 1px solid black;
}

#quote-order-content10 {
  grid-area: quote-order-content10;
}

#quote-order-content11 {
  grid-area: quote-order-content11;
}

#quote-order-content12 {
  grid-area: quote-order-content12;
  quote-order-bottom: 1px solid black;
}

#quote-order-content13 {
  grid-area: quote-order-content13;
  quote-order-bottom: 1px solid black;
}

@media only screen and (max-width: 600px) {
  .quote-order-container {
    grid-template-columns: 250px auto;
    grid-template-rows: 0.2fr auto;
    grid-template-areas:
      "quote-order-content0 quote-order-content0"
      "quote-order-content1 quote-order-content2"
      "quote-order-content3 quote-order-content4"
      "quote-order-content5 quote-order-content6"
      "quote-order-content7 quote-order-content8"
      "quote-order-content10 quote-order-content11"
      "quote-order-content12 quote-order-content13"
      "quote-order-content14 quote-order-content15";
  }
}

.package-tab-container {
  display: grid;
  grid-template-columns: 500px auto;
  grid-template-rows: 0.7fr auto;
  grid-template-areas:
    "package-tab-content0 package-tab-content0"
    "package-tab-content1 package-tab-content1"
    "package-tab-content2 package-tab-content2"
    "package-tab-content3 package-tab-content3"
    "package-tab-content4 package-tab-content4"
    "package-tab-content5 package-tab-content5"
    "package-tab-content6 package-tab-content7"
    "package-tab-content8 package-tab-content9"
    "package-tab-content10 package-tab-content11"
    "package-tab-content12 package-tab-content13"
    "package-tab-content14 package-tab-content15"
    "package-tab-content16 package-tab-content17"
    "package-tab-content18 package-tab-content19"
    "package-tab-content20 package-tab-content21"
    "package-tab-content22 package-tab-content23"
    "package-tab-content24 package-tab-content25"
    "package-tab-content26 package-tab-content26";
  /* "package-tab-content23 package-tab-content24"
    "package-tab-content25 package-tab-content25"
    "package-tab-content26 package-tab-content26"
    "package-tab-content27 package-tab-content27"
    "package-tab-content28 package-tab-content28"; */
  grid-gap: 0.5rem;
  border-radius: 25px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.788);
}

#package-tab-content0 {
  grid-area: package-tab-content0;
  text-align: center;
}

#package-tab-content1 {
  grid-area: package-tab-content1;
  text-align: center;
}

#package-tab-content2 {
  grid-area: package-tab-content2;
  background-color: rgba(152, 158, 158, 0.274);
  font-size: 16pt;
  font-weight: 500;
  border-radius: 10px;
}

#package-tab-content3 {
  grid-area: package-tab-content3;
}

#package-tab-content4 {
  grid-area: package-tab-content4;
  display: flex;
  justify-content: center;
}

#package-tab-content5 {
  grid-area: package-tab-content5;
}

#package-tab-content6 {
  grid-area: package-tab-content6;
}

#package-tab-content7 {
  grid-area: package-tab-content7;
}

#package-tab-content8 {
  grid-area: package-tab-content8;
}

#package-tab-content9 {
  grid-area: package-tab-content9;
}

#package-tab-content10 {
  grid-area: package-tab-content10;
}

#package-tab-content11 {
  grid-area: package-tab-content11;
}

#package-tab-content12 {
  grid-area: package-tab-content12;
  border-bottom: 1px solid black;
}

#package-tab-content13 {
  grid-area: package-tab-content13;
  border-bottom: 1px solid black;
}

#package-tab-content14 {
  grid-area: package-tab-content14;
}

#package-tab-content15 {
  grid-area: package-tab-content15;
}

#package-tab-content16 {
  grid-area: package-tab-content16;
  border-bottom: 1px solid black;
}

#package-tab-content17 {
  grid-area: package-tab-content17;
  border-bottom: 1px solid black;
}

#package-tab-content18 {
  grid-area: package-tab-content18;
}

#package-tab-content19 {
  grid-area: package-tab-content19;
}

#package-tab-content20 {
  grid-area: package-tab-content20;
  border-bottom: 1px solid black;
}

#package-tab-content21 {
  grid-area: package-tab-content21;
  border-bottom: 1px solid black;
}

#package-tab-content22 {
  grid-area: package-tab-content22;
}

#package-tab-content23 {
  grid-area: package-tab-content23;
  font-weight: 600;
}

#package-tab-content24 {
  grid-area: package-tab-content24;
  font-weight: 600;
  font-size: 15pt;
}

#package-tab-content25 {
  grid-area: package-tab-content25;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  grid-auto-flow: column;
}

#package-tab-content26 {
  grid-area: package-tab-content26;
}

#package-tab-content27 {
  grid-area: package-tab-content27;
}

#package-tab-content28 {
  grid-area: package-tab-content28;
}

@media only screen and (max-width: 600px) {
  #package-tab-content4 {
    grid-area: package-tab-content4;
    display: inline-block;
  }
  .package-tab-container {
    grid-template-columns: 250px auto;
    grid-template-rows: 0.2fr auto;
    grid-template-areas:
      "package-tab-content0 package-tab-content0"
      "package-tab-content1 package-tab-content1"
      "package-tab-content2 package-tab-content2"
      "package-tab-content3 package-tab-content3"
      "package-tab-content4 package-tab-content4"
      "package-tab-content5 package-tab-content5"
      "package-tab-content6 package-tab-content7"
      "package-tab-content8 package-tab-content9"
      "package-tab-content10 package-tab-content11"
      "package-tab-content12 package-tab-content13"
      "package-tab-content14 package-tab-content15"
      "package-tab-content16 package-tab-content17"
      "package-tab-content18 package-tab-content19"
      "package-tab-content20 package-tab-content21"
      "package-tab-content22 package-tab-content23"
      "package-tab-content24 package-tab-content25"
      "package-tab-content26 package-tab-content26";
  }
}

/*Cancel*/

.cancel-container {
  display: grid;
  grid-template-columns: 500px auto;
  grid-template-rows: 0.7fr auto;
  grid-template-areas:
    "cancel-content0 cancel-content0"
    "cancel-content1 cancel-content2"
    "cancel-content3 cancel-content4"
    "cancel-content5 cancel-content6"
    "cancel-content7 cancel-content8"
    "cancel-content9 cancel-content9 "
    "cancel-content10 cancel-content10"
    "cancel-content11 cancel-content11";
  /* "cancel-content12 cancel-content13"
    "cancel-content14 cancel-content15"
    "cancel-content16 cancel-content17"
    "cancel-content18 cancel-content19"
    "cancel-content20 cancel-content21"
    "cancel-content22 cancel-content23"
    "cancel-content24 cancel-content25"
    "cancel-content26 cancel-content26";
    "cancel-content23 cancel-content24"
    "cancel-content25 cancel-content25"
    "cancel-content26 cancel-content26"
    "cancel-content27 cancel-content27"
    "cancel-content28 cancel-content28"; */
  grid-gap: 0.5rem;
  border-radius: 25px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.788);
}

#cancel-content0 {
  grid-area: cancel-content0;
  text-align: center;
}

#cancel-content1 {
  grid-area: cancel-content1;
}

#cancel-content2 {
  grid-area: cancel-content2;
  font-size: 16pt;
  font-weight: 500;
  border-radius: 10px;
}

#cancel-content3 {
  grid-area: cancel-content3;
}

#cancel-content3 {
  grid-area: cancel-content3;
  border-bottom: 1px solid black;
}

#cancel-content4 {
  grid-area: cancel-content4;
}

#cancel-content5 {
  grid-area: cancel-content5;
}

#cancel-content6 {
  grid-area: cancel-content6;
}

#cancel-content7 {
  grid-area: cancel-content7;
}

#cancel-content8 {
  grid-area: cancel-content8;
}

#cancel-content9 {
  grid-area: cancel-content9;
  text-align: center;
}

#cancel-content10 {
  grid-area: cancel-content10;
}

#cancel-content11 {
  grid-area: cancel-content11;
  text-align: center;
}

#cancel-content12 {
  grid-area: cancel-content12;
  border-bottom: 1px solid black;
}

#cancel-content13 {
  grid-area: cancel-content13;
  border-bottom: 1px solid black;
}

#cancel-content14 {
  grid-area: cancel-content14;
}

#cancel-content15 {
  grid-area: cancel-content15;
}

#cancel-content16 {
  grid-area: cancel-content16;
  border-bottom: 1px solid black;
}

#cancel-content17 {
  grid-area: cancel-content17;
  border-bottom: 1px solid black;
}

#cancel-content18 {
  grid-area: cancel-content18;
}

#cancel-content19 {
  grid-area: cancel-content19;
}

#cancel-content20 {
  grid-area: cancel-content20;
  border-bottom: 1px solid black;
}

#cancel-content21 {
  grid-area: cancel-content21;
  border-bottom: 1px solid black;
}

#cancel-content22 {
  grid-area: cancel-content22;
}

#cancel-content23 {
  grid-area: cancel-content23;
  font-weight: 600;
}

#cancel-content24 {
  grid-area: cancel-content24;
  font-weight: 600;
  font-size: 15pt;
}

#cancel-content25 {
  grid-area: cancel-content25;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  grid-auto-flow: column;
}

#cancel-content26 {
  grid-area: cancel-content26;
}

#cancel-content27 {
  grid-area: cancel-content27;
}

#cancel-content28 {
  grid-area: cancel-content28;
}

@media only screen and (max-width: 600px) {
  .cancel-container {
    grid-template-columns: 250px auto;
    grid-template-rows: 0.2fr auto;
    grid-template-areas:
      "cancel-content0 cancel-content0"
      "cancel-content1 cancel-content2"
      "cancel-content3 cancel-content4"
      "cancel-content5 cancel-content6"
      "cancel-content7 cancel-content8"
      "cancel-content9 cancel-content9 "
      "cancel-content10 cancel-content10"
      "cancel-content11 cancel-content11";
  }
}

/*OrderList Container*/
.order-list-container {
  display: grid;
  grid-template-columns: 500px auto;
  grid-template-rows: 0.7fr auto;
  grid-template-areas:
    "order-list-content0 order-list-content0"
    "order-list-content1 order-list-content1"
    "order-list-content2 order-list-content2"
    "order-list-content3 order-list-content3"
    "order-list-content4 order-list-content5"
    "order-list-content6 order-list-content7"
    "order-list-content8 order-list-content9"
    "order-list-content10 order-list-content11";
  /* "order-list-content23 order-list-content24"
    "order-list-content25 order-list-content25"
    "order-list-content26 order-list-content26"
    "order-list-content27 order-list-content27"
    "order-list-content28 order-list-content28"; */
  grid-gap: 1rem;
  border-radius: 25px;
  justify-content: center;
}

.order-list-list-text {
  font-size: 16pt;
  font-weight: 500;
  color: rgb(61, 21, 21);
  font-family: Montserrat;
}

#order-list-content-update {
  grid-area: order-list-content-update;
}

#order-list-content0 {
  grid-area: order-list-content0;
  text-align: center;
}

#order-list-content1 {
  grid-area: order-list-content1;
  text-align: center;
}

#order-list-content2 {
  grid-area: order-list-content2;
  text-align: center;
}

#order-list-content3 {
  grid-area: order-list-content3;
  background-color: rgba(225, 235, 235, 0.938);
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: auto;
}

#order-list-content3 {
  grid-area: order-list-content3;
}

#order-list-content4 {
  grid-area: order-list-content4;
}

#order-list-content5 {
  grid-area: order-list-content5;
}

#order-list-content6 {
  grid-area: order-list-content6;
}

#order-list-content7 {
  grid-area: order-list-content7;
}

#order-list-content8 {
  grid-area: order-list-content8;
  border-list-bottom: 1px solid black;
}

#order-list-content9 {
  grid-area: order-list-content9;
  border-list-bottom: 1px solid black;
}

#order-list-content10 {
  grid-area: order-list-content10;
}

#order-list-content11 {
  grid-area: order-list-content11;
}

@media only screen and (max-width: 600px) {
  .order-list-container {
    grid-template-columns: 250px auto;
    grid-template-rows: 0.2fr auto;
    grid-template-areas:
      "order-list-content0 order-list-content0"
      "order-list-content1 order-list-content1"
      "order-list-content2 order-list-content2"
      "order-list-content3 order-list-content3"
      "order-list-content4 order-list-content5"
      "order-list-content6 order-list-content7"
      "order-list-content8 order-list-content9"
      "order-list-content10 order-list-content11";
  }
}
